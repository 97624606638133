import { useTranslation } from 'react-i18next'
import type { ReactNode } from 'react'
import { useHistory } from 'react-router-dom'
import type { MenuItemProps } from '@chakra-ui/react'

import { translations } from 'locales/translations'
import { PATHS } from 'consts/paths'
import { MY_PROFILE_PAGE_ENABLED } from 'consts/envVars'

import {
  useOciSession,
  useCurrentUser,
  useLogoutWithRedirectToRoot,
} from 'app/containers/common/Auth'
import { ActionItem } from 'app/components/ActionsMenu/styles'
import {
  ActionDivider,
  ActionsMenu,
} from 'app/components/ActionsMenu/ActionsMenu'
import { LanguageAccordion } from 'app/components/layout/chooseLanguage'
import { deliveryAddressesLinkConfig } from 'app/App/routes/authorized/routes/CustomerRoutes/navigationStructure/companySettingsSection'
import { ChangeIcon, LogoutIcon, UserIcon } from 'app/components/icons'

import { ChangeCompanyActions } from './components'
import { AccountMenuUserAndCompanyInfo } from './components/AccountMenuUserAndCompanyInfo'
import { SupportAndHelpActions } from './components/SupportAndHelpActions'

type CompanyAccountMenuProps = {
  triggerButton: ReactNode
  isLogoutAvailable: boolean
  areCompanyDetailsHidden?: boolean
}

const ChangeCompanyButton = (
  props: Pick<MenuItemProps, 'children' | 'onClick'>,
) => (
  <>
    <ActionItem icon={<ChangeIcon />} iconSpacing="16px" {...props} />
    <ActionDivider />
  </>
)

export const CompanyAccountMenu = ({
  triggerButton,
  isLogoutAvailable,
  areCompanyDetailsHidden,
}: CompanyAccountMenuProps) => {
  const { t } = useTranslation()
  const { mutate: logout } = useLogoutWithRedirectToRoot()
  const { isCustomer } = useCurrentUser()
  const { isOciSessionFlow } = useOciSession()
  const history = useHistory()

  const isMyProfileAvailable =
    !isOciSessionFlow && isCustomer && MY_PROFILE_PAGE_ENABLED

  return (
    <div style={{ zIndex: 2 }}>
      <ActionsMenu
        menuButton={triggerButton}
        contentWidth="320px"
        dataTestId="userMenu"
        placement={'bottom-end'}
        isWithoutPortal
      >
        <AccountMenuUserAndCompanyInfo
          areCompanyDetailsHidden={areCompanyDetailsHidden}
        />

        {!isOciSessionFlow && isCustomer && (
          <>
            <ActionItem
              iconSpacing="16px"
              icon={deliveryAddressesLinkConfig.icon}
              onClick={() => history.push(deliveryAddressesLinkConfig.path)}
            >
              {t(deliveryAddressesLinkConfig.titleMsg)}
            </ActionItem>
            <ActionDivider />
          </>
        )}

        <ChangeCompanyActions ButtonComponent={ChangeCompanyButton} />

        {(isLogoutAvailable || isMyProfileAvailable) && (
          <>
            {isMyProfileAvailable && (
              <ActionItem
                iconSpacing="16px"
                icon={<UserIcon />}
                onClick={() => history.push(PATHS.customer.myProfile)}
              >
                {t(translations.header.myProfile)}
              </ActionItem>
            )}
            {isLogoutAvailable && (
              <ActionItem
                icon={<LogoutIcon />}
                iconSpacing="16px"
                onClick={() => logout()}
                data-testid="logOut"
              >
                {t(translations.header.logout)}
              </ActionItem>
            )}
            <ActionDivider />
          </>
        )}

        <SupportAndHelpActions />

        <LanguageAccordion />
      </ActionsMenu>
    </div>
  )
}
