import { useTranslation } from 'react-i18next'
import { FiShare2 } from 'react-icons/fi'
import { Flex } from '@chakra-ui/react'

import { translations } from 'locales/translations'

import { Button } from 'app/components/Buttons/Button'
import { UserAssignIcon } from 'app/components/icons'
import {
  goToInviteToCollaboratePage,
  goToShareWithOthersPage,
} from 'app/pages/customers/ArticleLists/components'

import { Menu, type MenuProps } from './components'

const messages =
  translations.pages.customer.companyAndSettings.articlesLists.details

type HeaderActionsProps = MenuProps

export const HeaderActions = ({ id, name, accessLevel }: MenuProps) => {
  const { t } = useTranslation()

  const inviteToCollaborate = () =>
    goToInviteToCollaboratePage({ id, pushedFrom: 'DETAILS' })
  const shareWithOthers = () =>
    goToShareWithOthersPage({ id, pushedFrom: 'DETAILS' })

  return (
    <Flex gap="8px">
      {accessLevel === 'ADMIN' && (
        <>
          <Button
            variant="secondary"
            onClick={inviteToCollaborate}
            leftIcon={<UserAssignIcon />}
          >
            {t(messages.collaborate)}
          </Button>

          <Button
            variant="secondary"
            onClick={shareWithOthers}
            leftIcon={<FiShare2 size="20px" />}
          >
            {t(messages.share)}
          </Button>
        </>
      )}

      <Menu id={id} name={name} accessLevel={accessLevel} />
    </Flex>
  )
}
